//COLORS
$c_blue: #4A93FA;
$c_blue_dark: #1378C0;
$c_grey: #667785;
$c_grey_light: #e8eaef;
$c_grey_dark: #495057;
$c_white: #FFFFFF;
$c_red: #df6464;
$c_danger: #DC3545FF;
$c_warning: orange;
$c_success_background: #d4edda;
$c_success: #155724;


//MEDIA QUERIES
$w_smaller_screen: 1180px;
$w_tablet_screen: 1024px;
$w_mobile_screen: 768px;


//METRICS
$h_topbar: 13rem;
$h_topbar-tablet_screen: 10rem;
$h_topbar-mobile_screen: 8rem;







//@include breakpoints((
//        xlarge:   ( 1281px,  1680px ),
//        large:    ( 981px,   1280px ),
//        medium:   ( 737px,   980px  ),
//        small:    ( 481px,   736px  ),
//        xsmall:   ( 361px,   480px  ),
//        xxsmall:  ( null,    360px  )
//));
