:root, :host, html {
  font-size: 10px;
  //font-size: 62.5%;
}

body {
  //background-color: $c_blue_dark;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Montserrat, sans-serif;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

@import "abstract/variables";
@import "base/typography";
@import "components/buttons";
@import "components/forms";
@import "components/process-common";
@import "components/badge";
//https://dev.to/rembertdesigns/how-to-write-media-queries-with-sass-mixins-32e
