@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Signika:wght@300..700&display=swap');

// <uniquifier>: Use a unique and descriptive class name
// <weight>: Use a value from 100 to 900

.f_montserrat-normal {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

.f_signika-bold {
    font-family: "Signika", sans-serif;
    font-optical-sizing: auto;
    font-weight: bold;
    font-style: normal;
    font-variation-settings: "GRAD" 0;
}

h1 {
  font-size: 5.6rem;
  font-family: "Signika", sans-serif;
  font-weight: 600;
  font-variation-settings: "GRAD" 0;
  font-optical-sizing: auto;
}

h2 {
  font-family: "Signika", sans-serif;
  text-align: center;
  font-size: 4rem;
  font-weight: 500;
  margin: 0;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
  color: inherit;

  &:focus,
  &:hover,
  &:active {
    text-decoration: underline;
    color: #1378C0;
  }
}
