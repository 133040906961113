@import "abstract/variables";

.form-group {
  position: relative;
  margin-top: 50px; // To create space for floating inputs
  // margin-inline: auto; ? why do we need this
  // max-width: 400px;
  font-family: Montserrat, sans-serif;
  font-size: 16px;

  &__error-message {
    color: $c_danger;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
    min-width: 150px;
  }

  &__error-warn {
    color: $c_warning;
    margin-top: 10px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: bold;
  }
}

.form-control {
  font-size: 16px;
  width: 100%;
  padding: 8px 0;
  padding-right: 30px; // To avoid overlapping with the clear button
  color: #333;
  border: none;
  border-bottom: 1px solid #ddd;
  transition: border-color 250ms;
  background-color: transparent;

  &:focus {
    outline: none;
    border-bottom-color: #777;
  }

  &::placeholder {
    color: transparent;
  }

  // Hide Safari's autofill button
  &::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
  }
}

.label {
  position: absolute;
  top: 8px;
  left: 0;
  color: #43454e;
  pointer-events: none;
  transform-origin: left center;
  transition: transform 250ms;
  font-family: "Montserrat",  sans-serif;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.info {
  font-size: 12px;
  color:$c_grey;
  & .error {
    color: $c_red;
  }
}


.form-control:focus + .label,
.form-control:not(:placeholder-shown) + .label {
  transform: translateY(-140%) scale(1);
  font-weight: 700;
}

.clear {
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  right: -9px; // To visually align with inputs right edge
  transform: translateY(-50%);
  background: none;
  border: none;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #777;
  transition: color 250ms;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    color: #333;
  }
}

.form-control:placeholder-shown + .label + .clear {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffff00;
  transition: background-color 5000s ease-in-out 0s;
  // box-shadow: inset 0 0 20px 20px #23232329;
}

.checkbox-label {
  font-family: system-ui, sans-serif;
  font-size: 20px;
  line-height: 1.5;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  margin-bottom: 1.6rem;

  &__text {
    font-size: 16px;
  }
}

.checkbox-label + .checkbox-label {
  margin-top: 1em;
}

.checkbox-label--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.invalid-checkbox {
  color: $c_danger;
}
.checkbox-info p {
  font-family: Montserrat, sans-serif;
  font-size: 12px !important;
}
.checkbox-subinfo {
  margin-left: 15px;
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(0.225em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #000;
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:focus {
  outline: max(2px, 0.15em) solid currentColor;
  outline-offset: max(2px, 0.15em);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="date"] {
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  width: 100%; /* Powoduje, że cały input wywołuje kalendarz */
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0; /* Ukrywa domyślną ikonkę, ale zachowuje jej funkcjonalność */
  cursor: pointer;
  z-index: 2;
}
