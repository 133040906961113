@import "abstract/variables";

.cta, .cta:visited, .cta:active {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  color: $c_white;
  background-color: $c_blue;
  border: 1px solid $c_blue;
  padding: 1.7rem 5.5rem;
  border-radius: 0.7rem;
  font-size: 2rem;
  display: inline-block;
  transition: all ease-in 150ms;
  cursor: pointer;

  @media only screen and (max-width: $w_mobile_screen) {
        width: 100%;
        text-align: center;
  }

  &:disabled {
    background-color: $c_grey_light;
    color: $c_grey_dark;
    border: 1px solid $c_grey;
    cursor: not-allowed;
    box-shadow: none;
    pointer-events: none;
  }
}

.cta:hover {
  box-shadow: 0px 10px 20px #00000029;
  background-color: $c_white;
  color: $c_blue;
  &:disabled {
    color: $c_grey_light !important;
  }
}

.cta-white {
  background-color: $c_white;
  color: $c_blue;

  &:hover {
    background-color: $c_grey_light;
    color: $c_blue_dark;
  }
}

.cta--bold, .cta--bold:visited, .cta--bold:hover, .cta--bold:active {
  font-weight: bold;
}

.cta--100-w {
  width: 100%;
  text-align: center;
}


.cta:disabled, .cta--disabled, .cta--disabled:visited, .cta--disabled:active {
  background-color: $c_grey_light;
  color: $c_grey_dark;
  border: 1px solid $c_grey;
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
}

.cta--disabled:hover {
  background-color: $c_grey_light;
  color: $c_grey_dark;
  box-shadow: none;
}

