
.process-header {
  background: #4A93FA;
  color: #fff;
  padding: 40px 34px 40px;

  @media (min-width: 1260px) {
    padding: 50px 0 50px;
  }

  @media (min-width: 1520px) {
    padding: 50px 0 50px;
  }

  h1,
  h2 {
    font-family: "Signika", sans-serif;
    font-weight: 400;

    line-height: 1.5;
    max-width: 181rem;
    margin: 0 auto;

    @media (min-width: 1260px) {
      padding: 0 10rem 0;
      font-size: 56px;
    }

    @media (min-width: 1520px) {
      padding: 0 10rem 0;
      font-size: 56px;
    }

  }

  h1 {
    font-size: 43px;
    font-weight: 700;
    margin: 0 auto 0;
    line-height: 1.2;

    @media (min-width: 1520px) {
      font-size: 56px;
    }
  }

  h2 {
    text-transform: uppercase;
    text-align: left;
    font-size: 28px;
    margin: 0 auto 16px;

    @media (min-width: 1520px) {
      font-size: 32px;
    }

  }
}

.process-content {
  padding: 40px 34px 40px;
  max-width: 181rem;
  margin: 0 auto;

  @media (min-width: 1260px) {
    padding: 40px 10rem 40px;
  }

  h3 {
    font-family: "Signika", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    font-size: 32px;
    margin: 0 auto 0;
    text-transform: uppercase;
  }

  h4 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    line-height: 1.5;
    font-size: 24px;
    margin: 0 auto 0;
    //text-transform: uppercase;
  }

  p {
    font-size: 1.6rem;

    &.process-content__highlight {
      color: #4A93FA;
      font-size: 21px;
      font-weight: 500;
      font-family: "Signika", sans-serif;
      margin-top: 0;
      margin-bottom: 40px;
    }
  }

  &__form-section {
    margin-bottom: 40px;
  }

  &__form-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 130px;

    @media (min-width: 1260px) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__form-section-span {
    @media (min-width: 1260px) {
      grid-column: 1 / span 2;
    }
  }

}
