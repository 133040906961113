.badge {
  background-color: #666;
  color: white;
  padding: 4px 8px;
  text-align: center;
  border-radius: 5px;
  display: inline-block;
  text-transform: uppercase;
  max-width: 18rem;
  font-size: 1.6rem;
  font-weight: 700;
}

.badge-success {
  background-color: #369B00;
}

.badge-warning {
 background-color: #c65102;
}

.badge-danger {
  background-color: #960000;
}


.asterisk {
  color: $c_warning;
  font-weight: 700;
  font-size: 18px;
}
